import React from "react";
import classes from "./HeroSection.module.css";
import clsx from "clsx";
import { Text } from "../../../common";
import Marquee from "react-fast-marquee";
import Button from "../../../common/Button/Button";
import {
  FaDiscord,
  FaGithub,
  FaTelegram,
  FaYoutube,
  FaReddit,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { bottomIcon, heroBg, middleIcon, topIcon } from "../../../images";

const HeroSection = () => {
  const items = ["DAO", "DeFi", "Gaming", "NFT", "Onramp", "Bridge"];
  const socialLinks = [
    {
      logo: <FaDiscord className={classes.socialIcon} />,
      name: "Discord",
      link: "https://discord.com/invite/4zrW3aTWeS"
    },
    {
      logo: <FaXTwitter className={classes.socialIcon} />,
      name: "Twitter",
      link: "https://x.com/driftblockbot"
    },
    {
      logo: <FaGithub className={classes.socialIcon} />,
      name: "GitHub",
      link: "https://github.com/driftblock"
    },
    {
      logo: <FaTelegram className={classes.socialIcon} />,
      name: "Telegram",
      link: "https://t.me/driftblock"
    },
    {
      logo: <FaYoutube className={classes.socialIcon} />,
      name: "YouTube",
      link: ""
    },
    {
      logo: <FaReddit className={classes.socialIcon} />,
      name: "Reddit",
      link: ""
    },
  ];
  return (
    <section className={classes.wrapper}>
      <div className={clsx("container", classes.container)}>
        <div className={classes.topPart}>
          <Text base0 xl2 textCenter className={classes.subHeading}>
            One interface to rule them all
          </Text>
          <h2 className={classes.heading}>
            Unified Interface for Blockchain
          </h2>
          <div className={classes.itemsWrapper}>
            <Marquee autoFill={true}>
              <div className={classes.items}>
                {items.map((el, i) => (
                  <Text xl3 base800 key={i} className={classes.item}>
                    {el}
                  </Text>
                ))}
              </div>
            </Marquee>
          </div>
          <div className={classes.buttonAndInfo}>
            <Text xl base0 className={classes.info}>
              Drift makes everyday blockchain usage easy by abstracting away the complexities
            </Text>
            <div className={classes.buttonContainer}>
              {/* you can pass  href , onClick or to  whatever you prefer */}
              <Button base0 to="https://t.me/driftblockbot">
                Launch App
              </Button>
              <Button
                transparentBase0
                onClick={() => {
                  window.open("/Drift-Whitepaper.pdf", "_blank");
                }}
                className={classes.readDocsButton}
              >
                Whitepaper
              </Button>
            </div>
          </div>{" "}
          <img src={heroBg} alt="#" className={classes.heroBg} />
          <img src={topIcon} alt="#" className={classes.topIcon} />
          <img src={middleIcon} alt="#" className={classes.middleIcon} />
          <img src={bottomIcon} alt="#" className={classes.bottomIcon} />
        </div>
        <div className={classes.socialInfoContainer}>
          <Text xl2 base0 textCenter className={classes.socialInfo}>
            Have a question? Join our community
          </Text>{" "}
          <div className={classes.socialContainer}>
            {socialLinks.map((el, i) => (
              <a
                key={i}
                href={el.link}
                target="_blank"
                rel="noreferrer"
                className={classes.socialLink}
              >
                {" "}
                {el.logo}
                <Text xl2 base-950 bold>
                  <span className={classes.name}>{el.name}</span>
                </Text>
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
