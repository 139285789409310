import React from "react";
import classes from "./Footer.module.css";
import { Heading, Text, Wrapper } from "../../common";
import clsx from "clsx";

import {
  FaDiscord,
  FaGithub,
  FaTelegram,
  FaYoutube,
  FaReddit,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const socialLinks = [
    {
      logo: <FaDiscord className={classes.socialIcon} />,
      name: "Discord",
      link: "https://discord.com/invite/4zrW3aTWeS"
    },
    {
      logo: <FaXTwitter className={classes.socialIcon} />,
      name: "Twitter",
      link: "https://x.com/driftblockbot"
    },
    {
      logo: <FaGithub className={classes.socialIcon} />,
      name: "GitHub",
      link: "https://github.com/driftblock"
    },
    {
      logo: <FaTelegram className={classes.socialIcon} />,
      name: "Telegram",
      link: "https://t.me/driftblock"
    },
    {
      logo: <FaYoutube className={classes.socialIcon} />,
      name: "YouTube",
      link: ""
    },
    {
      logo: <FaReddit className={classes.socialIcon} />,
      name: "Reddit",
      link: ""
    },
  ];
  return (
    <Wrapper bg="#434AFF" className={classes.wrapper}>
      <div className={clsx("container", classes.container)}>
        <div className={classes.leftSide}>
          <h2 className={classes.heading}>Trustless Permissionless Platform</h2>
          <Text lg base0 className={classes.description}>
            Drift is a unified interface that allows cross-chain blockchain interactions directly from telegram
          </Text>
        </div>

        <div className={classes.linksContainer}>
          <div className={classes.links}>
            <div className={classes.headingAndLinks}>
              <Heading xl2 base0 className={classes.linkHeading}>
                Quick Links
              </Heading>
              <a
                href="https://t.me/driftblockbot"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Launch App
              </a>
              <a
                href="/Drift-Whitepaper.pdf"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Whitepaper
              </a>{" "}
              <a
                href="https://docs.driftblock.io"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Docs (Coming soon)
              </a>
            </div>
            <div className={classes.headingAndLinks}>
              <Heading base0 xl2>
                Resources
              </Heading>
              <a
                href="mailto:support@driftblock.io"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Support
              </a>
              <a
                href="#/"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Academy (Coming soon)
              </a>{" "}
            </div>{" "}
            {/* <div className={classes.headingAndLinks}>
              <Heading xl2 base0 className={classes.linkHeading}>
                Products
              </Heading>
              <a
                href="#/"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Product Title
              </a>
              <a
                href="#/"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Product Title
              </a>{" "}
              <a
                href="#/"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Product Title
              </a>
            </div> */}
            <div className={classes.socialContainer}>
              {socialLinks.map((el, i) => (
                <a
                  key={i}
                  href="#/"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.socialLink}
                >
                  {el.logo}
                  <span className={classes.name}>{el.name}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={clsx("container", classes.copyRightContainer)}>
        <Text base base0>
          © 2024. All rights reserved{" "}
          <a href="#/" target="_blank" className={classes.bold}>
            driftblock.io
          </a>
        </Text>
        <div className={classes.privacyAndTerms}>
          <a href="#/" target="_blank" className={classes.link}>
            Privacy Policy
          </a>{" "}
          <a href="#/" target="_blank" className={classes.link}>
            Terms of Use
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
