import React from "react";
import classes from "./DetailsAndStatistics.module.css";
import { Heading, Text, Wrapper } from "../../../common";
import clsx from "clsx";
import { peerToPeer } from "../../../images";
import Button from "../../../common/Button/Button";
const DetailsAndStatistics = () => {

  let enable_stats = false;
  const data = [
    {
      img: peerToPeer,
      label: "Secure ",
      title: "Transactions",
      description:
        "Ensuring your data security,  we leverage AES-256 encryption to protect your data from threats.",
      learnMore: "",
    },
    {
      img: peerToPeer,
      label: "Incentivized ",
      title: "Participation",
      description:
        "Earn rewards & benefits by actively engaging in our platform",
      learnMore: "",
    },
    {
      label: "Intuitive and User-friendly",
      title: "Interface",
      description:
        "Our platform is designed to be user-friendly and intuitive, making it easy for you to navigate.",
      learnMore: "",
    },
  ];

  const statistics = [
    {
      label: "Projects",
      amount: 100,
    },
    {
      label: "Transactions",
      amount: 10000,
    },
    {
      label: "Community ",
      amount: 10000000,
    },
    {
      label: "Validators",
      amount: 10000000,
    },
    {
      label: "Accounts",
      amount: 10000000,
    },
    {
      label: "Contracts",
      amount: 10000000,
    },
  ];
  function formatNumber(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(0) + "B";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(0) + "K";
    }
    return num;
  }
  return (
    <Wrapper bg="#434AFF">
      <div className={clsx("container", classes.container)}>
        <div className={classes.infoWrapper}>
          {data.map((el, i) => (
            <div className={classes.infoContainer} key={i}>
              {el.img && <img src={el.img} alt="#" className={classes.img} />}
              <div>
                <Heading xl5 base0>
                  <span className={classes.light}>{el.label}</span> {el.title}
                </Heading>
              </div>
              <div className={classes.descriptionAndButton}>
                <Text lg base0>
                  {el.description}
                </Text>
                {/* you can pass either onClick or href */}
                <Button transparentBase0 href="#" className={classes.button}>
                  Learn More
                </Button>
              </div>
            </div>
          ))}
        </div>

       { enable_stats && 
         <div className={classes.statistics}>
         <Heading textCenter base0 className={classes.statisticsHeading}>
           <span className={classes.light}>See the Statistics of</span>{" "}
           Drift
         </Heading>

         <div className={classes.allStatistics}>
           {statistics.map((el, i) => (
             <div className={classes.box} key={i}>
               <Heading xl4 base950 textCenter>
                 {formatNumber(el.amount)}+
               </Heading>
               <Text lg base950 textCenter>
                 {el.label}
               </Text>
             </div>
           ))}
         </div>
       </div>
       }
      </div>
    </Wrapper>
  );
};

export default DetailsAndStatistics;
